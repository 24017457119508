import React from 'react';
import './App.css';
import MainPage from './pages/MainPage';

function App() {
  return (
    <MainPage />
  );
}

/**
 * Old function to convert emoji into base64 tab icon
 */
function abc() {
  let canvas = document.createElement('canvas');
  canvas.width = 64;  // Set width
  canvas.height = 64; // Set height
  let ctx = canvas.getContext('2d');
  ctx.font = '48px serif';  // Set font size and style
  ctx.fillText('💻', 0, 48); // Draw emoji on canvas
  let dataUrl = canvas.toDataURL(); // Convert to base64
  console.log(dataUrl);
}


export default App;
