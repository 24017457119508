import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainPage.css'

function MainPage() {

  const summary = 'A seasoned software developer with over 10 years of experience specializing in Java, adept in developing and maintaining complex systems for credit unions and handling legal documents. Expertise includes designing and integrating robust APIs, leveraging the Spring framework for scalable and secure applications, and managing projects with outsourcing teams to deliver high-quality software solutions. Proven track record in collaborating with cross-functional teams, understanding client needs, and ensuring compliance with industry standards, making them a valuable asset in any tech-driven environment focused on financial and legal software solutions.';

  return (
    <div className="App">
        <div className="App-header">
          <div className='container mt-4'>
            <div className='row'>
              <h1>Erick Jacques</h1>
            </div>
            <div className='row'>
              <div className='col-md-2 offset-md-4'>
                <p>Software Developer</p>
              </div>
              <div className='col-md-2'>
                <p>Waterloo Region - Ontario</p>
              </div>
            </div>
          </div>
        </div>

        <div className='content'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-md-3 offset-md-2'>
              <p>
                {summary}
              </p>
            </div>
            <div className='col-md-4 col-sm-12'>
              <div className='profile-image'>
              </div>
            </div>
          </div>
        </div>

        <div className="App-footer">
          <p>Connect with me:</p>
          <a href="https://www.linkedin.com/in/erick-jacques/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} color='white' size='2x' />
          </a>
        </div>
    </div>
  );
}
export default MainPage;